<template>
    <div>
        <div class="lunbo">
            <div class="hidden-xs-only nav">
                <el-carousel :interval="3000" :height="imgHeight+'px'">
                    <el-carousel-item  v-for="(item,index) in banner" :key="index">
                        <el-image style="cursor:pointer;width:100%" ref="image" :src="item.ori_img" @click="bantiao(item.carousel_href)"></el-image>
                    </el-carousel-item>
                </el-carousel>
            </div>
            <div class="hidden-sm-and-up">
                <el-carousel :interval="3000" height="230px" indicator-position= "none">
                    <el-carousel-item  v-for="(item,index) in banner" :key="index">
                        <el-image style="cursor:pointer;width:100%" ref="image" :src="item.carousel_img" @click="bantiao(item.carousel_href)"></el-image>
                    </el-carousel-item>
                </el-carousel>
            </div>
        </div>
        <div style="position: absolute;z-index:99;width:100%" :style="{'top':imgHeight+30+'px'}" v-if="this.$i18n.locale == 'zh'">
            <div style="width:60%;background: #fff;margin:0 auto;height:100px;padding:10px;box-shadow: 0px 0px 7px 0px rgba(0,0,0,0.15);">
                <div style="display: flex;justify-content: space-between;padding-left:30px;padding-right:30px;">
                    <div style="width:80px;cursor: pointer;" @click="nav('productinfo?class_id=1&page=1&id=0')">
                        <el-image class="t1" :src="require('@/assets/index/icon_genset.png')"></el-image>
                        <div style="text-align: center;">发电机组</div>
                    </div>
                    <div style="width:80px;cursor: pointer;" @click="nav('energy')">
                        <el-image class="t1" :src="require('@/assets/index/icon_Hybridenergy.png')"></el-image>
                        <div style="text-align: center;">新能源</div>
                    </div>
                    <div style="width:80px;cursor: pointer;" @click="nav('productinfo?class_id=2&page=1&id=2')">
                        <el-image class="t1" :src="require('@/assets/index/icon_dualpower.png')"></el-image>
                        <div style="text-align: center;">双电源</div>
                    </div>
                    <div style="width:80px;cursor: pointer;" @click="nav('productinfo?class_id=4&page=1&id=4')">
                        <el-image class="t1" :src="require('@/assets/index/icon_engineering_machinery.png')"></el-image>
                        <div style="text-align: center;">工程机械</div>
                    </div>
                    <div style="width:80px;cursor: pointer;" @click="nav('productinfo?class_id=69&page=1&id=69')">
                        <el-image class="t1" :src="require('@/assets/index/icon_ship_control.png')"></el-image>
                        <div style="text-align: center;">船舶控制</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="appmain" style="margin-top:100px;">
            <div class="hometitle" @click="nav('productinfo')">{{$t('index.NewProducts')}}</div>
            <div class="homehengtiao"></div>
            <div style="width:92%;margin:0 auto">
                <el-row :gutter="20" style="margin-top:3%">
                    <el-col :span="12" :xs="24" v-for="(item,index) in xinpin" :key="index">
                        <div style="cursor: pointer;background:#ffffff;margin-bottom: 4.8%" v-if="index == 0" @click="nav('product/'+xinpinfirst.get_image_list[0].product_id)">
                            <div style="max-width:370px;margin:0 auto" class="t1">
                                <el-image style="width:100%" :src="xinpinfirst.get_image_list[0].product_img"></el-image>
                            </div>
                            <div class="chanpinname" style="padding-top:10px;height: 50px;">
                                <span>{{xinpinfirst.product_name}}</span>
                            </div>
                            <div class="chanpinjieshao" style="padding-bottom: 30px;">{{xinpinfirst.product_feature}}</div>
                        </div>
                    </el-col>
                    <el-col :span="12" :xs="24">
                        <el-row :gutter="20">
                            <el-col :span="12" :xs="24" v-for="(item,index) in xinpin" :key="index" style="cursor: pointer;" >
                                <div style="background:#ffffff;margin-bottom: 4.8%" @click="nav('product/'+item.product_id)">
                                    <div class="chanpin2img t1" >
                                        <el-image  style="width:100%" :src="item.get_image_list[0].product_img"></el-image>
                                    </div>
                                    <div class="chanpinname" style="height: 40px;">
                                        <span>{{item.product_name}}</span>
                                    </div>
                                    <div class="chanpinjieshao">{{item.product_feature}}</div>
                                </div>
                            </el-col>
                        </el-row>
                    </el-col>
                </el-row>
            </div>
            <div>
                <div class="hometitle" @click="nav('applicationcase')">{{$t('layout.application')}}</div>
                <div class="homehengtiao" style="margin-bottom:20px"></div>
            </div>
            <el-row class="yyinfo">
                <el-col :span="11" :xs="24">
                    <div class="yyleft" @click="nav('projectinfo/'+anlifirst.project_id)">
                        <el-image style="width:100%" :src="require('@/assets/index/case1.png')"></el-image>
                        <div class="content">
                            <div style="width:80%;margin:0 auto;">
                                <div class="yydate">{{anlifirst.ymd}}</div>
                                <div class="yytitle" @click.stop="nav('applicationcase?class_id='+anlifirst.class_id)">{{anlifirst.class_name}}</div>
                                <div class="yytitle">{{anlifirst.project_title}}</div>
                                <div class="yyfubiao">{{anlifirst.project_desc_text}}</div>
                            </div>
                        </div>
                    </div>
                </el-col>
                <el-col :span="13" :xs="24">
                    <el-col :span="12" :xs="24" v-for="(item,index) in anli" :key="index" :style="{'margin-top': index==2 || index==3 ? '20px' : '0px'}">
                        <div class="yyright" @click="nav('projectinfo/'+item.project_id)">
                            <el-image style="width:100%" :src="item.backimg"></el-image>
                            <div class="content">
                                <div style="width:80%;margin:0 auto;">
                                    <div class="yydate">{{item.ymd}}</div>
                                    <div class="yytitle" @click.stop="nav('applicationcase?class_id='+item.class_id)">{{item.class_name}}</div>
                                    <div class="yytitle">{{item.project_title}}</div>
                                </div>
                            </div>
                        </div>
                    </el-col>
                </el-col>
            </el-row>
        </div>
        <el-row>
            <el-col :span="24">
                <div class="xuanze">
                    <div class="hidden-xs-only">
                        <el-image style="width:100%" :src="require('@/assets/index/choose.jpg')"></el-image>
                    </div>
                    <div class="wenzi">
                        <div class="title">{{$t('index.xuanze')}}</div>
                        <div class="homehengtiao" style="background:#fff;margin-bottom:20px"></div>
                        <div class="title2">
                            {{$t('index.dizao')}}
                        </div>
                        <div class="neirong">
                            <el-row :gutter="40">
                                <el-col :span="12" :xs="24">
                                    <div class="item">
                                        <div class="titles right">
                                            <div class="num">01</div>
                                            <div class="xiaobiao">{{$t('index.zishen')}}</div>
                                        </div>
                                        <div class="jieshao">
                                            {{$t('index.lingyu')}}
                                        </div>
                                        <div class="dian"></div>
                                    </div>
                                </el-col>
                                <el-col :span="12" :xs="24">
                                    <div class="item">
                                        <div class="titles lefts">
                                            <div class="num">02</div>
                                            <div class="xiaobiao">{{$t('index.yingyong')}}</div>
                                        </div>
                                        <div class="jieshao">
                                            {{$t('index.yytext')}}
                                        </div>
                                        <div class="dianl"></div>
                                    </div>
                                </el-col>
                                <el-col :span="12" :xs="24">
                                    <div class="item">
                                        <div class="titles right">
                                            <div class="num">03</div>
                                            <div class="xiaobiao">{{$t('index.rongyu')}}</div>
                                        </div>
                                        <div class="jieshao">
                                            {{$t('index.tixi')}}
                                        </div>
                                        <div class="dian"></div>
                                    </div>
                                </el-col>
                                <el-col :span="12" :xs="24">
                                    <div class="item">
                                        <div class="titles lefts">
                                            <div class="num">04</div>
                                            <div class="xiaobiao">{{$t('index.fengfu')}}</div>
                                        </div>
                                        <div class="jieshao">
                                            {{$t('index.chanpins')}}
                                        </div>
                                        <div class="dianl"></div>
                                    </div>
                                </el-col>
                                <el-col :span="12" :xs="24">
                                    <div class="item">
                                        <div class="titles right">
                                            <div class="num">05</div>
                                            <div class="xiaobiao">{{$t('index.yanfa')}}</div>
                                        </div>
                                        <div class="jieshao">
                                            {{$t('index.yongyou')}}
                                        </div>
                                        <div class="dian"></div>
                                    </div>
                                </el-col>
                                <el-col :span="12" :xs="24">
                                    <div class="item">
                                        <div class="titles lefts">
                                            <div class="num">06</div>
                                            <div class="xiaobiao">{{$t('index.zhuanye')}}</div>
                                        </div>
                                        <div class="jieshao">
                                            {{$t('index.zhuanyes')}}
                                        </div>
                                        <div class="dianl"></div>
                                    </div>
                                </el-col>
                            </el-row>
                        </div>
                    </div>
                </div>
            </el-col>
            <el-col :span="24">
                <div class="hometitle" @click="nav('news')">{{$t('index.recentNews')}}</div>
                <div class="homehengtiao" style="margin-bottom:20px"></div>
            </el-col>
        </el-row>
           
        <div class="dtinfo">
            <el-row :gutter="20">
                <el-col :span="8" :xs="24" v-for="(item,index) in dongtaifirst" :key="index">
                    <div class="dtleft" @click="nav('news_info/'+item.news_id)" style="padding-bottom:20px">
                        <el-image  :src="item.news_img" style="width:100%;"></el-image>
                        <div style="padding-left:2%;font-size: 16px;font-weight: bold;margin-top:10px;font-family: SourceHanSansCN-Medium;overflow: hidden;text-overflow: ellipsis;white-space: nowrap;">{{item.news_title}}</div>
                        <div style="padding-left:2%;font-size: 14px;color: rgba(51, 51, 51, 0.5);margin-top:10px;font-family: OPPOSans-M">{{item.ymd}}</div>
                    </div>
                </el-col>
                <el-col :span="8" :xs="24">
                    <div class="dtleft" style="padding:3%;padding-bottom:20px">
                        <div v-for="(item,index) in dongtai" :key="index" @click="nav('news_info/'+item.news_id)">
                            <div class="dttitle">{{item.news_title}}</div>
                            <div class="dtdate">{{item.ymd}}</div>
                        </div>
                    </div>
                </el-col>
            </el-row>
        </div>
    </div>
</template>
<script>
import { Message } from "element-ui";
import { index,productscollectAdd } from "@/api/api"; 
export default {
    data(){
        return{
            title:'',
            imgHeight:"",
            banner:[],
            xinpinfirst:{},
            xinpin:[],
            anlifirst:{},
            anli:[],
            dongtaifirst:{},
            dongtai:[]
        }
    },
    metaInfo () {
        return {
            title: this.title,
            meta: [
                {
                    name: 'keyWords',
                    content: this.$t('seo.indexkeywords')
                },
                {
                    name: 'description',
                    content: this.$t('seo.indexdescription')
                }
            ]
        }
    },
    created(){
        this.title = this.$t('layout.home')+'-'+this.$t('seo.indextitle')
        this.getList()
    },
    mounted() {
        this.imgLoad();
        // 监听窗口变化，使得轮播图高度自适应图片高度
        window.addEventListener("resize", () => {
            this.imgHeight = this.$refs.image[0].height;
        });
    },
    methods:{
        async getList(){
            const{data:res} = await index()
            res.banner.forEach((item) => {
                item.ori_img = process.env.VUE_APP_BASE_API+item.ori_img
                item.carousel_img = process.env.VUE_APP_BASE_API+item.carousel_img
            });
            res.xinpin.forEach((item)=>{
                item.get_image_list.forEach((items)=>{
                    items.product_img = process.env.VUE_APP_BASE_API+items.product_img
                })
            })
            res.anli.forEach((item,index)=>{
                item.project_img = process.env.VUE_APP_BASE_API+item.project_img
                if(index == 1){
                    item.backimg = require('@/assets/index/case2.png')
                }else if (index == 2){
                    item.backimg = require('@/assets/index/case3.png')
                }else if (index == 3){
                    item.backimg = require('@/assets/index/case5.png')
                }else if (index == 4){
                    item.backimg = require('@/assets/index/case4.png')
                }
            })
            res.dongtai.forEach((item)=>{
                item.news_img = process.env.VUE_APP_BASE_API+item.news_img
            })
            this.xinpinfirst = res.xinpin[0]
            this.anlifirst = res.anli[0]
            this.banner = res.banner
            this.xinpin = res.xinpin.splice(1)
            this.anli = res.anli.splice(1)
            this.dongtaifirst = [res.dongtai[0],res.dongtai[1]]
            this.dongtai = res.dongtai.splice(2)
        },
        imgLoad() {
            this.$nextTick(function() {
                // 获取窗口宽度*图片的比例，定义页面初始的轮播图高度
                this.imgHeight = document.body.clientWidth*0.8/3.5
            });
        },
        nav(item){
            this.$router.push('/'+item,'_blank');
        },
        bantiao(item){
            let items = process.env.VUE_APP_HOST+item
            window.open(items,'_blank');
        }
    }
}
</script>
<style lang="scss" scoped>
  .t1:hover {
	// -webkit-animation:sucaijiayuan 1s .1s ease both;
	// -moz-animation:tada 1s .1s ease both;
    transform: scale(1.2);
  }
  @-webkit-keyframes sucaijiayuan {
    0% {
        -webkit-transform:scale(1)
    }
    10%,20% {
        -webkit-transform:scale(1) rotate(-2deg)
    }
    30%,50%,70%,90% {
        -webkit-transform:scale(1.1) rotate(2deg)
    }
    40%,60%,80% {
        -webkit-transform:scale(1.1) rotate(-2deg)
    }
    100% {
        -webkit-transform:scale(1) rotate(0)
    }
  }
  .lunbo {
    margin-top: 0px;
  }
  .hometitle{
      text-align: center;
      height: 24px;
      font-size: 24px;
      font-family: SourceHanSansCN-Bold;
      line-height: 24px;
      font-weight: bold;
      color: #333333;
      margin-top: 3%;
      cursor: pointer;
  }
  .homehengtiao{
      width: 32px;
      height: 2px;
      background: #0052D9;
      margin: 0 auto;
      margin-top: 10px;
  }
  .chanpin2img{
      width: 152px;
      margin: 0 auto;
  }
  .chanpinname{
      font-size: 17px;
      color: #333333;
      text-align: center;
      font-family: OPPOSans-H;
      margin-top: 2px;
  }
  .chanpinjieshao{
      font-size: 14px;
      font-family: SourceHanSansCN-Regular;
      font-weight: 400;
      color: rgba(51, 51, 51, 0.6);
      text-align: center;
      line-height: 18px;
      margin-top: 5px;
      text-overflow :ellipsis; /*让截断的文字显示为点点。还有一个值是clip意截断不显示点点*/
      white-space :nowrap; /*让文字不换行*/
      overflow : hidden; /*超出要隐藏*/
      padding-bottom: 10px;
      padding-left: 4%;
      padding-right: 4%;
  }
  .yyinfo{
    width: 91%;
    margin: 0 auto;
    .yydate{
        font-size: 16px;
        color: #FFFFFF;
        font-weight: 500;
        font-family: OPPOSans-M;
    }
    .yytitle{
        margin-top:15px;
        font-size:18px;
        font-weight: bold;
        line-height: 30px;
    }
    .yyfubiao{
        margin-top:15%;
        font-size: 16px;
        line-height: 34px;
        font-weight: 400;
        font-family: SourceHanSansCN-Normal;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 6;
        overflow: hidden;
        word-break: break-all;
    }
    .yyleft{
        width: 100%;
        margin: 0 auto;
        position: relative;
        cursor: pointer;
        .content{
            width: 100%;
            position: absolute;
            top: 8%;
            z-index: 2;
            color: #FFFFFF;
        }
    }
    .yyright{
        width: 96%;
        margin: 0 auto;
        margin-left: 5%;
        position: relative;
        cursor: pointer;
        .content{
            width: 100%;
            position: absolute;
            top: 15%;
            z-index: 2;
            color: #FFFFFF;
        }
    }
  }
  .dtinfo{
    width: 73%;
    margin: 0 auto;
    .dtleft{
        width: 100%;
        max-height: 400px;
        margin: 0 auto;
        background: #FFFFFF;
        cursor: pointer;
        .dttitle{
            margin-right: 3%;
            font-family: SourceHanSansCN-Medium;
            font-size: 16px;
            font-weight: bold;
            color: #333333;
            margin-top: 12px;
            text-overflow :ellipsis; /*让截断的文字显示为点点。还有一个值是clip意截断不显示点点*/
            white-space :nowrap; /*让文字不换行*/
            overflow : hidden; /*超出要隐藏*/
        }
        .dtdate{
            font-size: 12px;
            font-family: OPPOSans-M;
            font-weight: normal;
            color: rgba(51, 51, 51, 0.5);
            margin-top: 10px;
        }
    }
  }
  
  .xuanze{
      margin-top: 20px;
      width: 100%;
      position: relative;
      .wenzi{
          width: 100%;
          top: 0px;
          position: absolute;
          z-index: 8;
          .title{
              font-family: SourceHanSansCN-Bold;
              text-align: center;
              font-weight: bold;
              color: #FFFFFF;
              font-size: 24px;
              margin-top: 5%;
          }
          .title2{
              text-align: center;
              font-size: 18px;
              font-family: SourceHanSansCN-Regular;
              font-weight: 400;
              color: #FFFFFF;
          }
          .neirong{
              width: 73%;
              margin: 0 auto;
              margin-top: 3%;
              .item{
                  width: 100%;
                  height: 20vh;
                  background: #FFFFFF;
                  margin-top: 3%;
                  padding: 20px;
                  padding-right:40px ;
                  padding-left:30px ;
                  cursor: pointer;
                  position: relative;
                  .dian{
                      width: 10px;
                      height: 24px;
                      position: absolute;
                      background: #2DC84D;
                      right: 0px;
                      top: 40px;
                      display: none;
                  }
                  .dianl{
                      width: 10px;
                      height: 24px;
                      position: absolute;
                      background: #2DC84D;
                      left: 0px;
                      top: 40px;
                      display: none;
                  }
                  .titles{
                      line-height: 30px;
                      display: flex;
                      margin-top: 3%;
                      .num{
                          color: #0052D9;
                          font-size: 20px;
                          font-family: OPPOSans-H;
                          margin-right: 10px;
                          line-height: 35px;
                      }
                      .xiaobiao{
                          font-family: SourceHanSansCN-Bold;
                          font-weight: bold;
                          color: #000000;
                          font-size: 20px;
                      }
                  }
                  .jieshao{
                      margin-top: 10px;
                      color: #333333;
                      font-size: 16px;
                      font-family: SourceHanSansCN-Regular;
                      font-weight: 400;
                      text-align: justify;
                      line-height: 23px;
                  }
                  .lefts{
                      justify-content:flex-start;
                  }
                  .right{
                      justify-content:flex-end;
                  }
              }
              .item:hover{
                  background: #0052D9;
                  .dian{
                      display: block;
                  }
                  .dianl{
                      display: block;
                  }
                  .titles{
                      .num{
                            color: #fff;
                        }
                        .xiaobiao{
                            color: #fff;
                        }
                    }
                    .jieshao{
                        color: #fff;
                    }
              }
          }
      }
  }
  @media screen and (max-width: 800px) {
    .chanpin2img{
        max-width: 350px !important;
        width: 100%;
    }
    .yyinfo{
        width: 100%;
        .yytitle{
            font-size: 14px;
        }
        .yyleft{
            width: 100%;
            margin: 0 auto;
            position: relative;
            .content{
                position: absolute;
                top: 8%;
                z-index: 2;
                color: #FFFFFF;
            }
        }
        .yyright{
            width: 100%;
            margin: 0 auto;
            position: relative;
            .content{
                position: absolute;
                top: 5%;
                z-index: 2;
                color: #FFFFFF;
            }
        }
    }
    .dtinfo{
        width: 100%;
    }
    .xuanze{
        width: 100%;
        .wenzi{
            width: 100%;
            position: static;
            .title{
                font-family: SourceHanSansCN-Bold, SourceHanSansCN;
                text-align: center;
                font-weight: bold;
                color: #333333;
                font-size: 24px;
                margin-top: 20px;
            }
            .title2{
                text-align: center;
                font-size: 18px;
                font-family: SourceHanSansCN-Regular, SourceHanSansCN;
                font-weight: 400;
                color: #333333;
            }
            .neirong{
                width: 100%;
                margin: 0 auto;
                margin-top: 0px;
                .item{
                    width: 100%;
                    height: 200px !important;
                    background: rgba(179, 179, 179, 0.746);
                    margin-top: 5%;
                    .jieshao{
                        font-size: 13.5px;
                    }
                }
            }
        }
    }
  }
  @media screen and (max-width: 1280px) {
    .yyinfo{
        width: 100%;
        .yytitle{
            font-size: 14px;
        }
    }
    .dtinfo{
        width: 90%;
        margin: 0 auto;
        .dtleft{
            width: 100%;
            max-height: 400px;
            margin: 0 auto;
            background: #FFFFFF;
            cursor: pointer;
            .dttitle{
                margin-right: 3%;
                font-family: SourceHanSansCN-Medium;
                font-size: 14px;
                font-weight: bold;
                color: #333333;
                margin-top: 12px;
                text-overflow :ellipsis; /*让截断的文字显示为点点。还有一个值是clip意截断不显示点点*/
                white-space :nowrap; /*让文字不换行*/
                overflow : hidden; /*超出要隐藏*/
            }
            .dtdate{
                font-size: 12px;
                font-family: OPPOSans-M;
                font-weight: normal;
                color: rgba(51, 51, 51, 0.5);
                margin-top: 5px;
            }
        }
    }
    .xuanze{
        width: 100%;
        .wenzi{
            width: 100%;
            .title{
                font-size: 24px;
                margin-top: 10px;
            }
            .title2{
                text-align: center;
                font-size: 18px;
            }
            .neirong{
                .item{
                    width: 100%;
                    height: 150px;
                    .titles{
                        margin-top: 0px;
                        .num{
                            margin-right: 10px;
                        }
                        .xiaobiao{
                            font-size: 20px;
                        }
                    }
                    .jieshao{
                        margin-top: 0px;
                        font-size: 13.5px;
                    }
                }
            }
        }
    }
  }
  @media screen and (max-width: 1540px) {
    .xuanze{
        .wenzi{
            .title{
                font-size: 24px;
                margin-top: 10px;
            }
            .title2{
                text-align: center;
                font-size: 18px;
            }
            .neirong{
                margin-top: 5px;
                .item{
                    height: 170px;
                    .dian{
                        top: 36px;
                    }
                    .dianl{
                        top: 36px;
                    }
                    .titles{
                        margin-top: 12px;
                        .num{
                            margin-right: 10px;
                        }
                        .xiaobiao{
                            font-size: 20px;
                        }
                    }
                    .jieshao{
                        margin-top: 0px;
                        font-size: 13.5px;
                    }
                }
            }
        }
    }
  }
</style>